
$font1: 'Open Sans', sans-serif;

$extra_small:12px;
$small_font_size:14px;
$body_font_size:16px;
$match_title_size:18px;
$widget_title_font_size:20px;
$widget_font_size2:24px;
$heading3:30px;
$heading2:36px;

/*
=====================
font weight

*/


$wightLight:300;
$wightRegular:400;
$wightMedium:500;
$wightSemi:600;
$wightBold:700;
$wightEBold:800;
$wightBlack:900;


